import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isLoading: false,
    restore:{
        mobiles:[],
    },
    error: null,
}
const slice = createSlice({
    name: 'restore',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setRestore(state,action){
            state.restore = action.payload;
        },
        addMobileNumber(state,action){
            let mobiles = state.restore.mobiles;
            if(!mobiles.includes(action.payload)){
                mobiles.push(action.payload);
                state.restore.mobiles = mobiles;
                
            }
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
       
    }
});
export default slice.reducer;
export const {addMobileNumber} = slice.actions;
