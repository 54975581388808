import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage";
import warehouseReducer from './slice/warehouse';
import notificationReducer from './slice/notification';
import restoreReducer from './slice/restore';

const rootPersistConfig = {
    key:'root',
    storage,
    keyPrefix:'redux-',
    whitelist:['restore'],
}

const rootReducer = combineReducers({
    notification:notificationReducer,
    warehouse:warehouseReducer,
    restore:restoreReducer,
});
export {rootPersistConfig, rootReducer};